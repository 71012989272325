.container {
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #fff;
    text-align: left;
//    border: 1px solid #fff;

//     border-radius: 20px;
    .leftContainer,
    .rightContainer {
        // width: 100%;

        .section1Container,
        .section2Container,
        .section3Container {
            text-decoration: none;
            color: #000;
            
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 15px;
            padding: 10px;

            &:hover {
                background-color: aliceblue;
            }

            .iconContainer {
                img {
                    margin-top: 25px;
                    width: 35px;
                }
            }

            .textContainer {
                width: 250px;

                .headingContainer {
                    font-weight: bolder;
                    margin-bottom: 2px;
                }

                .infoContainer {
                }
            }
        }

        .hr {
            background-color: #aaa;
            height: 1px;
        }
    }
    .divider {
        margin: 15px;
        height: 230px;
        width: 1px;
        border: 1px solid #aaa;
    }
}
