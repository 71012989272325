.Admin_main_container{
    display: flex;
    position: relative;
}
.Admin_sidebare_nav{
    width: 200px;
    background-color: #0d47a1 ;
    height: 90vh;
    position: absolute;
    margin: 10px;
    border-radius: 10px;
}
.dashboard_nav_text{
color: white;
font-size: 22px;
align-items: center;
margin-left: 20px;
margin-top: 20px;
cursor: pointer;
}    
.Admin_right_section{
    margin-left: 250px;
}

.login_main_container{
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .login_main_container1{
        margin-top: 5%;
    width: 350px;
    height: 450px;
    border-radius: 32px;
    box-shadow: -13px 21px 45px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    }
    .login_Input{
        width: 80%;
        height: 40px;
        border-radius: 10px;
        border: 1px solid black;
        margin-top: 30px;
        padding: 10px;
        font-size: 18px;
        margin-left: 7%;
    }
    .login_btn{
    width: 200px;
    height: 35px;
    background-color:#0d47a1;
    border: 1px solid #0d47a1;
    border-radius: 20px;
    font-size: 20px;
    color: white;
    margin-left: 20%;
    margin-top: 50px;
    }
    
    .login_logo{
        margin-top: 10%;
        margin-left: 20%;
    }