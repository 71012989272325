.container {
    // margin-top: 100px;
    // border: 1px solid #f00;
    padding: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #0d47a1;

    @media only screen and (width< 400px) {
        padding: 5px;
    }

    .imageRow {
        // border: 1px solid #f00;
        text-align: left;
        margin: 2% 7%;
    }

    .textRow {
        // border: 1px solid #f00;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 30px;
        margin: 2% 8%;

        @media only screen and (width< 1000px) {
            flex-wrap: wrap;
        }

        .columns {
            // border: 1px solid #f00;
            // width: 100%;
            height: 100%;
            color: #fff;
            text-align: left;

            h3 {
                line-height: 30px;
                margin-bottom: 15px;
                // font-weight: 700;
                font-size: 16px;
                
            }

            ul {
                list-style: none;
                li {
                    font-weight: 400;
                    font-size: 13px;
                    margin-bottom: 15px;

                    .navLink {
                        text-decoration: none;
                        color: #e1e8f3;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .contact {
            display: flex;
            flex-direction: column;
            width: 370px;

            .contactContainer {
                background-color: #e9f1ff;
                padding: 15px;
                border-radius: 20px;

                .heading {
                    color: #0d47a1;
                    font-weight: 700;
                    font-size: 20px;
                }
                .info {
                    color: #263238;
                    margin-top: 5px;
                    margin-bottom: 15px;
                }
                .inputContainer {
                    // border: 1px solid #000;
                    border-radius: 5px;
                    height: 50px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    overflow: hidden;
                    background-color: #cadfff;
                    padding: 3px;

                    .icon {
                        display: inline-block;
                        font-size: 20px;
                        // height: 50px;
                        width: 50px;
                        color: #0d47a1;
                    }

                    input {
                        border: none;
                        outline: none;
                        height: 50px;
                        background-color: #cadfff;
                        color: #0d47a1;
                        font-size: 16px;
                    }
                }

                .submit {
                    margin-top: 15px;
                    background-color: #0d47a1;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 35px;
                    width: 80px;
                    font-size: 13px;
                    margin-left: 50%;
                    transform: translateX(-50%);
                    border-radius: 23px;
                    cursor: pointer;
                }
            }
            .socialLinksContainer {
                margin-top: 15px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 25px;

                .link {
                    font-size: 24px;
                }
            }
        }
    }
}
