.container {
    padding: 15px;
    background-color: #fff;

    .row1Container,
    .row2Container,
    .row3Container {
        cursor: default;
        display: flex;
        justify-content: center;
        align-items: center;

        .imageContainer {
            // border: 1px solid #000;
            padding: 10px;

            &:hover {
                background-color: aliceblue;
            }

            img {
                margin: 10px;
                width: 80px;
                height: 30px;
            }
        }

        .ibm {
            // border: 1px solid #f00;

            img {
                // width: 30px;
                height: 14px;
            }
        }
    }
}
