.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    padding: 15px;
    width: 560px;
    background-color: #fff;

    .leftSection,
    .rightSection {
        .row {
            padding: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 30px;
            text-decoration: none;

            &:hover {
                background-color: aliceblue;
            }

            .imageContainer {
                img {
                    width: 30px;
                }
            }
            .textContainer {
                font-weight: bolder;
                width: 150px;
                text-align: left;
                color: #000;

                hr {
                    margin-top: 40px;
                    margin-bottom: 20px;
                }
            }
        }

        .hr {
            height: 1px;
            background-color: #ccc;
            margin: 5px;
            width: 70%;
            margin-left: 30%;
        }
    }

    .divider {
        background-color: #ccc;
        width: 1px;
        height: 200px;
    }
}
