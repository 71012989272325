.container {
    // border: 1px solid #f00;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 99;

    .row1 {
        // border: 1px solid #f00;
        z-index: 99;
        display: flex;
      //  justify-content: center;
        align-items: center;
        gap: 25%;

        @media only screen and (width<1175px) {
            gap: 20%;
        }
        @media only screen and (width<1115px) {
            gap: 90px;
        }
        @media only screen and (width<972px) {
            gap: 50px;
        }

        @media only screen and (width<900px) {
            // justify-content: space-between;
            padding-left: 20px;
            padding-right: 20px;
        }

        .headerImageContainer {
            // border: 1px solid #000;
            height: 100px;
            // width: 100%;
            margin-left: 7%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @media only screen and (width<900px) {
                width: 100%;
                margin-left: 5%;


            }
            @media only screen and (width<600px) {
                width: 100%;
                margin-left: 3%;

            }

            .headerImage {
                // width: 200px;

                @media only screen and (width<1000px) {
                    width: 165px;
                }

                @media only screen and (width<400px) {
                    width: 165px;
                }
            }
        }

        .navContainer {
            // border: 1px solid #0f0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            position: relative;
            height: 50px;
            display: flex;
            align-items: center;

            .pageLinkContainer {
                // border: 1px solid #f00;
                cursor: default;
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                color: #0d47a1;
                font-size: 15px;
                // font-weight: bolder;
                

                // @media only screen and (width<1150px) {
                //     display: none;
                // }
                @media only screen and (width<900px) {
                    display: none;
                    font-size: 14px;

                }

                .dropDownContainer {
                    display: none;
                    position: absolute;
                    top: 100%;
                    border: 1px solid #ccc;
                    left: -150px;
                }

                .capabilitiesContainer {
                    left: -350px;
                }

                .companiesContainer {
                    left: -200px;
                }

                .resourcesContainer {
                    left: -350px;
                }

                &:hover {
                    .dropDownContainer {
                        display: block;
                    }
                }

                .dropDownContainer {
                    display: none;
                }

                .capabilitiesNavLink {
                    color: #0d47a1;
                    text-decoration: none;
                    cursor: pointer;
                }
            }

            .contact {
                height: 30px;
                width: 106px;
                border-radius: 50px;
                background-color: #0d47a1;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                cursor: pointer;

                // @media only screen and (width<1150px) {
                //     display: none;
                // }
                @media only screen and (width<900px) {
                    display: none;
                }
            }

            .dropDownIconContainer {
                width: 50px;
                height: 40px;
                // background-color: #000;
                display: none;
                position: relative;
                cursor: pointer;

                // @media only screen and (width<1150px) {
                //     display: block;
                // }
                @media only screen and (width<900px) {
                    display: block;
                }

                .line {
                    position: absolute;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    height: 3px;
                    width: 60%;
                    background-color: #333;
                    transition: transform 0.3s ease;
                }
                .line1 {
                    transform: translateY(-8px);
                    transform-origin: top left;
                }
                .line3 {
                    transform: translateY(8px);
                    transform-origin: bottom left;
                }
            }
        }
    }

    .responsiveContainer {
        @media only screen and (width<900px) {
            height: 0;
            overflow: hidden;
        }

        z-index: 99;
        // box-shadow: inset 0 0 10px 10px #000;
        position: fixed;
        top: 100px;
        // right: 5px;
        // background-color: #0d47af;
        background-color: aliceblue;
        width: 100%;
        overflow: hidden;
        transition: height 0.2s ease;

        display: flex;
        justify-content: center;
        align-items: center;

        .responsiveInnerContainer {
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            // margin: 15px;
            // padding: 15px;
            // border: 5px solid #f00;

            .servicesLink,
            .solutionsLink,
            .capabilitiesLink,
            .companyLink,
            .resourcesLink,
            .contactLink {
                // background-color: #000;
                color: #0d47a1;
                height: 40px;
                margin: 1px;
                padding-left: 15px;
                text-align: left;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;

                &:hover {
                    background-color: #eee;
                }

                .servicesText {
                }
                .servicesAccordionSign,
                .solutionsAccordionSign,
                .companyAccordionSign,
                .resourcesAccordionSign {
                    font-size: 30px;
                    font-weight: bolder;
                    margin-right: 50px;

                    transition: transform 0.3s ease;
                }
            }
            .contactLink,
            .capabilitiesLink {
                cursor: pointer;
                text-decoration: none;
                color: #0d47a1;
            }

            .servicesNavLinks,
            .solutionsNavLinks,
            .companyNavLinks,
            .resourcesNavLinks {
                text-align: left;
                height: 0;
                overflow: hidden;
                transition: height 0.3s ease;

                .subNavLink {
                    display: block;
                    color: #0d47a1;
                    text-decoration: none;
                    margin-left: 15px;
                    margin-right: 60px;
                    padding: 3px;
                    padding-left: 15px;
                    // height: 25px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 14px;

                    &:hover {
                        // text-decoration: underline;
                        background-color: #0d47a1;
                        color: #fff;
                    }
                }
            }
        }
    }
}
