@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Add any additional styling for the banner here */
  

html {
    scroll-behavior: smooth;
}
p{
    font-family: Inter !important;
}
span{
    font-family: Inter !important;
}
.App {
}

.header {
    position: relative;
    z-index: 1;
}

.content {
    position: relative;
    margin-top: 100px;
    min-height: 100vh;
}

#myBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: red;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
}

#myBtn:hover {
    background-color: #555;
}

.footer {
    /* margin-top: 50px; */
}
