.container {
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #fff;
    text-align: left;

    .leftContainer,
    .rightContainer {
        width: 100%;

        .section1Container,
        .section2Container {
            text-decoration: none;
            color: #000;
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 15px;
            padding: 15px;
            height: 60px;
            width: 300px;

            &:hover {
                background-color: aliceblue;
            }

            .iconContainer {
                img {
                    // border: 1px solid #f00;
                    width: 30px;
                }
            }

            .textContainer {
                // width: 300px;
                font-weight: bolder;
            }
        }

        .hr {
            background-color: #ccc;
            height: 1px;
        }
    }

    .divider {
        margin: 15px;
        height: 170px;
        width: 1px;
        border: 1px solid #ccc;
    }
}
