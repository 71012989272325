.blog-table {
    width: 100%;
    border-collapse: collapse;
  }
  .Heading_Admin{
    font-size: 25px;
    font-family: Inter;
    font-weight: 550;
    margin-top: 30px;
    margin-left:20px ;
  }
  .blog-table th,
  .blog-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  .contact_link{
    font-family: 20px;
    font-family: 600;
    margin-top: 10px;
    cursor: pointer;
  }
  .contact_link:hover{
    color: #0d47a1;
  }
  /* SearchBar.css */
.search-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.search-input {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.results {
  width: 100%;
  max-width: 400px;
  margin-top: 10px;
}

.result-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
}

.result-item:hover {
  background-color: #f1f1f1;
}.download-button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}
/* DateRangeFilter.css */
.date-range-filter {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.date-range-filter .date-picker {
  flex: 1;
}

.date-range-filter .filter-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.date-range-filter .filter-button:hover {
  background-color: #0056b3;
}


.download-button:hover {
  background-color: #45a049;
}
/* EventDetails.css */
.event-list {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.event {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event.active {
  background-color: #e0ffe0;
  border-color: #80c080;
}

.event.inactive {
  background-color: #ffe0e0;
  border-color: #c08080;
}

.event h2 {
  margin: 0;
  font-size: 1.5em;
}

.event .status {
  font-weight: bold;
  color: #555;
}

.eventContainer {
  width: 60%;
  height: 100vh;
  overflow-x: scroll;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.darkHeading {
  width: 100%;
  text-align: center;
  font-size: 2em;
  color: #fff; /* changed to white for better visibility */
  background-color: #333; /* dark background color */
  padding: 10px; /* added padding for better spacing */
  border-radius: 10px 10px 0 0; /* rounded corners only at the top */
  margin-bottom: 20px;
}

.eventContainer div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.eventLabel {
  font-size: 1.2em;
  font-weight: bold;
  color: #555;
}

.eventInfo {
  font-size: 1.2em;
  margin-left: 20px;
  color: #777;
}

@media (max-width: 768px) {
  .eventContainer {
      width: 90%;
      padding: 10px;
  }

  .darkHeading {
      font-size: 1.5em;
  }

  .eventLabel,
  .eventInfo {
      font-size: 1em;
  }
}

  .guestMainContainer{
display: flex;
gap:3vw;
justify-content: center;
  }
  .blog-table th {
    background-color: #f2f2f2;
  }
  
  .blog-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .blog-table tbody tr:hover {
    background-color: #e9e9e9;
  }
  
  .blog-table td {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  

  .admin-panel {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  h1 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  /* p {
    font-size: 16px;
    
  } */
  
  
  .document-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  
  .document-card {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 4px;
  }
  
  a {
    display: block;
  }
  